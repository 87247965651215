#header, #main, #footer {
    float: left;
    width: 100%;
    padding: 2.5rem;
}

#header {
    height: 300px;
}
#header blockquote {
    font-size: 23px;
}
#header .slider {
    display: none;
}

#main .two-cols {
    max-width: none;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
}

/* Person in background image should not be hidden by the quote. */
#header.header_person {
    padding-top: 300px;
    height: auto;
    background-position-x: 88%;
}

.project #header {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0;
}
.project #header h1 {
    font-size: 23px;
    margin-bottom: -8px;
}
.project-gallery img {
    width: 100%;
    float: left;
}
.project-gallery img:not(:last-child) {
    margin-bottom: 14px;
}
